export function excelAutoWidth(wb) {
  let ray = []
  let item = wb['Sheets']['Sheet1']
  console.log(item)
  for (const key in item) {
    if (item[key].t) {
      if (item[key].t == 's' && item[key].v !== '√') {
        let obj = {
          name: key.slice(0, 1),
          items: item[key],
        }
        ray.push(obj)
      }
    }
  }
  let rayZ = ray.splice(0, ray.length)
  let widthList = [
    { name: 'A', length: 0 },
    { name: 'B', length: 0 },
    { name: 'C', length: 0 },
    { name: 'D', length: 0 },
    { name: 'E', length: 0 },
    { name: 'F', length: 0 },
    { name: 'G', length: 0 },
    { name: 'H', length: 0 },
  ]
  for (let index = 0; index < rayZ.length; index++) {
    widthList.forEach((i) => {
      if (i.name === rayZ[index].name) {
        i.length =
          rayZ[index].items.v.length >= i.length
            ? rayZ[index].items.v.length
            : i.length
      }
    })
  }
  widthList.forEach((e) => {
    wb['Sheets']['Sheet1']['!cols'].push({
      wpx: e.length * 15,
    })
  })
  return wb
}
