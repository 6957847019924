<template>
  <div>
    <div class="mid_box">
      <img
        style="width: 15px"
        src="../../assets/images/Examine_img/back.png"
        alt=""
        @click="$router.go(-1)"
      />
      <span style="margin-right: 15px" @click="$router.go(-1)">返回</span>
      <!-- <el-button type="primary" class="edit" @click="handleShow"
        >抽取题目<i class="el-icon-circle-plus-outline el-icon--right"></i
      ></el-button> -->
      <el-button
        :disabled="disabled"
        type="primary"
        class="edit"
        @click="dialogVisible1 = true"
        >新增提问<i class="el-icon-circle-plus-outline el-icon--right"></i
      ></el-button>
      <span style="margin-top: 5px; margin-left: 5px">
        <img
          style="cursor: pointer"
          src="../../assets/images/Examine_img/del.png"
          @click="deleteItem"
        />
      </span>
      <el-button
        v-show="!showRefult && !disabled"
        style="
          margin-left: 10px;
          border-color: #5a8bff;
          background-color: #f3f3fb;
          color: #5a8bff;
        "
        type="primary"
        plain
        icon="el-icon-document-checked"
        @click="completeExam"
        >完成检查</el-button
      >
      <el-button
        style="
          margin-left: 10px;
          border-color: #5a8bff;
          background-color: #f3f3fb;
          color: #5a8bff;
        "
        type="primary"
        plain
        icon="el-icon-chat-line-square"
        @click="handleSwitch"
        >{{ showRefult ? '未审核' : '已审核'
        }}<span
          v-if="!showRefult && tableData2.length != 0"
          style="color: red; font-weight: 600"
        >
          +{{ tableData2.length }}</span
        ></el-button
      >
      <el-button
        :disabled="disabled"
        style="margin-left: 10px"
        type="primary"
        class="edit"
        @click="getPdf()"
        >打印检查表</el-button
      >
      <el-button
        :disabled="disabled"
        style="margin-left: 10px"
        type="primary"
        class="edit"
        @click="exportExcel2()"
        >导出检查表</el-button
      >
    </div>
    <div style="overflow: hidden; overflow-y: auto">
      <el-table
        v-if="!showRefult"
        :data="tableData.slice(start, end)"
        :height="elementHeight"
        style="width: 100%"
        highlight-current-row
        :row-class-name="tableRowClassName"
        :row-style="selectedstyle"
        id="pdfDom"
        @row-click="rowClick"
        :header-cell-style="{
          background: '#EDF0F7',
          color: '#000000',
        }"
        border
      >
        <el-table-column type="index" label="序号" width="55">
        </el-table-column>
        <!-- <el-table-column prop="years" label="年份" width="60">
        </el-table-column> -->
        <el-table-column prop="compName" label="公司" width="100">
        </el-table-column>
        <el-table-column prop="deptName" label="部门" width="100">
        </el-table-column>
        <!-- <el-table-column prop="functionName" label="职能" width="100">
        </el-table-column>
        <el-table-column prop="fileNo" label="文件编号" width="150">
        </el-table-column> -->
        <!-- <el-table-column prop="fileName" label="文件名称" width="200">
        </el-table-column> -->
        <el-table-column prop="questionRemark" label="问题"> </el-table-column>
        <el-table-column
          prop="auditResult"
          align="center"
          :label="isOneCheck == 0 ? '是否完成整改' : '是否需要整改'"
          width="200"
        >
          <template slot-scope="scope">
            <div class="auditResult_box" v-if="isshowAns">
              <div v-if="isOneCheck">
                <span>{{ scope.row.auditResult?'是':'否' }}</span>
              </div>
              <div v-else>不适用</div>
            </div>
            <div class="auditResult_box" v-else>
              <div
                @click="changeResult(scope.row, 1, '是')"
                :class="{ auditactive: scope.row.auditResult == 1 }"
              >
                <span v-if="isOneCheck == 0">是</span>
                <span v-else>已符合</span>
              </div>
              <div
                @click="changeResult(scope.row, 0, '否')"
                :class="{ auditactive: scope.row.auditResult == 0 }"
              >
                <span v-if="isOneCheck == 0">否</span>
                <span v-else>是</span>
              </div>
              <div
                v-show="isOneCheck == 1"
                @click="changeResult(scope.row, 3, '不合适')"
                :class="{ auditactive: scope.row.auditResult == 3 }"
              >
                不合适
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="improvement"
          align="center"
          width="180"
          :label="isOneCheck == 0 ? '改进措施' : '参考措施'"
        >
          <template slot-scope="scope">
            <div class="AnswerBox">
              <el-button
                type="primary"
                class="button"
                plain
                @click="lookAnswer(scope.row)"
                >点击查看</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-table
        v-else
        :data="tableData2.slice(start, end)"
        :height="elementHeight"
        style="width: 100%"
        highlight-current-row
        id="pdfDom"
        :row-class-name="tableRowClassName2"
        :row-style="selectedstyle2"
        @row-click="rowClick2"
        :header-cell-style="{
          background: '#EDF0F7',
          color: '#000000',
        }"
        border
      >
        <el-table-column type="index" label="序号" width="50">
        </el-table-column>
        <!-- <el-table-column prop="years" label="年份" width="60">
        </el-table-column> -->
        <el-table-column prop="compName" label="公司" width="100">
        </el-table-column>
        <el-table-column prop="deptName" label="部门" width="100">
        </el-table-column>
        <!-- <el-table-column prop="functionName" label="职能" width="100">
        </el-table-column>
        <el-table-column prop="fileNo" label="文件编号" width="150">
        </el-table-column> -->
        <el-table-column prop="fileName" label="文件名称" width="200">
        </el-table-column>
        <el-table-column prop="questionRemark" label="问题" width="450">
        </el-table-column>
        <el-table-column
          prop="auditResult"
          align="center"
          :label="isOneCheck == 0 ? '是否完成整改' : '是否需要整改'"
          width="200"
        >
          <template slot-scope="scope">
            <div class="auditResult_box" v-if="isshowAns">
              <div v-if="isOneCheck">
                <span>{{ scope.row.auditResult?'是':'否' }}</span>
              </div>
              <div v-else>不适用</div>
            </div>
            <div class="auditResult_box" v-else>
              <div
                @click="changeResult(scope.row, 1, '是')"
                :class="{ auditactive: scope.row.auditResult == 1 }"
              >
                <span v-if="isOneCheck == 0">是</span>
                <span v-else>已符合</span>
              </div>
              <div
                @click="changeResult(scope.row, 0, '否')"
                :class="{ auditactive: scope.row.auditResult == 0 }"
              >
                <span v-if="isOneCheck == 0">否</span>
                <span v-else>是</span>
              </div>
              <div
                v-show="isOneCheck == 1"
                @click="changeResult(scope.row, 3, '不合适')"
                :class="{ auditactive: scope.row.auditResult == 3 }"
              >
                不合适
              </div>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column
          prop="imagesCount"
          label="照片列表"
          align="center"
          width="180px
        "
        >
          <template slot-scope="scope">
            <div class="img_box" @click="openImg(scope.row)">
              <img src="../../assets/images/Examine_img/add.png" alt="" />
              <span v-show="scope.row.imagesCount === 0">请添加图片</span>
              <span
                v-show="scope.row.imagesCount > 0"
                style="margin-right: 50%"
                >{{ scope.row.imagesCount }}</span
              >
              <img src="../../assets/images/Examine_img/picture.png" alt="" />
            </div>
          </template>
        </el-table-column> -->

        <el-table-column
          prop="answer"
          :label="isOneCheck == 0 ? '改进措施' : '参考措施'"
        >
          <template slot-scope="scope">
            <el-button
              type="primary"
              class="button"
              plain
              @click="lookAnswer(scope.row)"
              >点击查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      :page-sizes="[10, 20, 50]"
      :page-size="10"
      layout="total, sizes, prev, pager, next, jumper"
      :total="showRefult ? tableData2.length : tableData.length"
    >
    </el-pagination>
    <Dialog
      title="抽取题目"
      :showDialog="showDialog"
      width="28%"
      :showTowText="true"
      @handleClose="handleClose"
      @handleReqData="drawQuestions"
    >
      <div class="dialog-contain">
        <div class="box">
          <span style="padding-left: 20px">时间</span>
          <div>
            <el-checkbox
              label="近一年"
              v-model="recentYears"
              true-label="1"
              @change="changRecentYears('1')"
            ></el-checkbox>
            <el-checkbox
              label="近两年"
              v-model="recentYears"
              true-label="2"
              @change="changRecentYears('2')"
            ></el-checkbox>
            <el-checkbox
              label="近三年"
              v-model="recentYears"
              true-label="3"
              @change="changRecentYears('3')"
            ></el-checkbox>
          </div>
        </div>
        <div class="box">
          <span style="padding-left: 20px">抽题目数</span>
          <div class="inp">
            <span>共 {{ this.drawnNumber2 }} 道题，抽取题目数</span
            ><span class="btn" @click="handleDrawnNumber(0)">―</span
            ><input type="text" v-model="drawnNumber" /><span
              class="btn"
              @click="handleDrawnNumber(1)"
              >+</span
            >
          </div>
        </div>
      </div>
    </Dialog>
    <Dialog
      title="新增提问"
      :showDialog="dialogVisible1"
      width="25%"
      :showTowText="true"
      @handleReqData="toAddASK"
      @handleClose="dialogVisible1 = false"
    >
      <div style="margin-bottom: 20px" class="text">
        <div style="display: flex">
          <div style="margin: 20px; width: 80px">问题描述</div>
          <el-input
            type="textarea"
            :rows="5"
            placeholder="请输入内容"
            v-model="question"
          >
          </el-input>
        </div>
      </div>
    </Dialog>
    <Dialog
      title="建议改进措施"
      :showDialog="dialogVisibleAsk"
      width="50%"
      :showTowText="true"
      @handleClose="handleAnswer"
      @handleReqData="handleAnswer"
    >
      <div class="text">
        <span>判断</span><span>{{ detail.judge }}</span>
      </div>
      <div class="text">
        <span>改进措施</span><span>{{ detail.improvement }}</span>
      </div>
    </Dialog>
    <Dialog
      title="审核结果"
      :showDialog="dialogVisibleResult"
      width="50%"
      showTowText="true"
      @handleClose="closeResultBox"
      @handleReqData="reqResult()"
      top="8vh"
    >
      <el-form label-width="100px">
        <!-- <el-form-item label="审核结果：" v-if="showRefultDialog">
          <span style="font-weight: bold; color: black">{{ judge }}</span>
          <el-radio
            v-model="curRow.UnqualityType"
            v-show="judge === '不符合'"
            label="轻微不符合"
            >轻微不符合</el-radio
          >
          <el-radio
            v-model="curRow.UnqualityType"
            v-show="judge === '不符合'"
            label="严重不符合"
            >严重不符合</el-radio
          >
        </el-form-item> -->
        <el-form-item
          :label="isOneCheck == 0 ? '未完成原因：' : '整改措施：'"
          v-if="showRefultDialog"
        >
          <el-input
            v-model="correctMeasure"
            type="textarea"
            :rows="2"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="完成情况：" v-if="showRefultDialog">
          <el-input
            v-model="completion"
            type="textarea"
            :rows="2"
            placeholder="请输入"
          ></el-input>
        </el-form-item> -->
        <div class="date">
          <span class="demonstration">完成时间：</span>
          <el-date-picker
            v-model="completeTime"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </div>
        <el-form-item label="上传照片：">
          <!-- :action="this.BUrl + '/api/UpLoadFiles/UploadImg'"
            :on-success="getaddr" -->
          <el-upload
            list-type="picture-card"
            ref="upload"
            accept=".JPG, .PNG, .JPEG,.jpg, .png, .jpeg"
            :headers="token"
            :on-preview="handlePictureCardPreview"
            :http-request="uploadFile"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <div>已上传图片</div>
          <span class="ImgDel" v-for="(item, index) in srcList" :key="index">
            <el-image
              style="width: 100px; height: 100px"
              :src="item.picNameAndAddr"
              :preview-src-list="srcList"
              @mouseover="overimg(index)"
            >
            </el-image>
            <i
              class="el-icon-delete-solid"
              v-show="index === Curindex"
              @click="delImg(item.id, index)"
            ></i>
          </span>
        </el-form-item>
      </el-form>
    </Dialog>
  </div>
</template>

<script>
import { baseURL, get, post, deleteMethod, put } from '../../api/http'
import Dialog from '../../components/dialog/Dialog.vue'
import { compressImage } from '../../modules/CompressImage'
import { excelAutoWidth } from '../../modules/Utils'
import { exportExcel } from '../../modules/ExportExcel'

export default {
  components: {
    Dialog,
  },
  data() {
    return {
      disabled: this.$route.query.disabled ? true : false, //从档案管理页面进来只看，不可操作
      token: {
        Authorization:
          'Bearer ' + JSON.parse(window.sessionStorage.getItem('token')),
      },
      htmlTitle: '内外审检查表',
      BUrl: baseURL[process.env.NODE_ENV + ''],
      showRefult: false, //是否显示已审核列表
      tableData: [],
      currentPage: 1,
      start: 0,
      end: 10,
      curSize: 10,
      tableRowIndex: null,
      tableItem: {},
      // 抽题
      showDialog: false,
      ischeck: false,
      recentYears: '1',
      drawnNumber: 0,
      drawnNumber2: 0,
      // 审核不通过
      auditResult: '',
      completion: '',
      isOneCheck: '',
      dialogVisibleResult: false,
      showRefultDialog: false, //是否显示改进弹窗
      srcList: [],
      isshowAns: false,
      Cid: null,
      excelAutoWidth: excelAutoWidth,
      Curindex: 0,
      correctMeasure: '',
      completeTime: '',
      // 改进措施
      dialogVisibleAsk: false,
      judge: '',
      detail: {},
      // 第二个表格
      tableItem2: {},
      tableRowIndex2: null,
      tableData2: [],
      // 新增提问
      question: '',
      dialogVisible1: false,
      value1: '',
      tableData1: [],
      elementHeight:0,
    }
  },
  mounted() {
    this.getTableList()
    this.getElementHeight()
    this.isOneCheck = this.$route.query.checkStatus
  },
  methods: {
    getTableList() {
      get(
        '/api/InOutAuditList/GetByInOutAuditId?InOutAuditId=' +
          this.$route.query.InOutAuditId
      ).then((resp) => {
        this.tableData = []
        this.tableData1 = resp.data
        this.tableData2 = []
        if (resp.code == 200) {
          resp.data.map((item) => {
            if (item.auditResult == null) {
              this.tableData.push(item)
            } else {
              this.tableData2.push(item)
            }
          })
        }
      })
    },

    handleSwitch() {
      this.showRefult = !this.showRefult
      this.start = 0
      this.end = 10
      this.currentPage = 1
    },
    rowClick(row) {
      this.tableRowIndex = row.index
      this.tableItem = row
      this.tableData.map((item) => {
        item.checked = false
      })
      row.checked = true
    },

    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex
    },
    selectedstyle({ rowIndex }) {
      if (this.tableRowIndex == rowIndex) {
        return {
          'background-color': '#E2E8FA !important',
          'font-weight': 'bold !important',
          color: '#555555',
        }
      }
    },
    // 分页
    handleSizeChange(e) {
      this.curSize = e
      this.start = (this.currentPage - 1) * this.curSize
      this.end = this.start + this.curSize
    },
    handleCurrentChange(e) {
      this.start = (e - 1) * this.curSize
      this.end = this.start + this.curSize
    },
    // 抽题
    handleClose() {
      this.showDialog = false
    },
    changRecentYears(i) {
      this.recentYears = i
      this.getDrawnNumber()
    },
    drawQuestions() {
      post(
        '/api/InOutAudit/AgainExtract?InOutAuditId=' +
          this.$route.query.InOutAuditId +
          '&ExtractCount=' +
          this.drawnNumber
      )
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              type: 'success',
              message: '抽题成功',
            })
            this.getTableList()
            this.showDialog = false
          } else {
            this.$message.error(resp.message)
          }
        })
        .catch(() => {
          this.$message.error('抽题失败')
        })
    },
    handleShow() {
      if (this.tableData2.length != 0) {
        this.$message({
          type: 'warning',
          message: '已有审核题目，不可重新抽题',
        })
        return
      }
      this.showDialog = true
      this.getDrawnNumber()
    },
    getDrawnNumber() {
      get(
        '/api/InOutAudit/GetTitleList?CompId=' +
          this.$route.query.CompId +
          '&AuditType=' +
          this.$route.query.typeBtnIndex +
          '&Years=' +
          this.recentYears
      ).then((resp) => {
        if (resp.code == 200) {
          this.drawnNumber = this.drawnNumber2 = resp.data.questionCount
        }
      })
    },
    handleDrawnNumber(i) {
      if (this.drawnNumber2 == 0) {
        this.$message({
          type: 'warning',
          message: '暂无抽题数',
        })
        return
      }
      if (
        (this.drawnNumber == 0 && i == 0) ||
        (this.drawnNumber == this.drawnNumber2 && i == 1)
      ) {
        this.$message({
          type: 'warning',
          message: '已超出范围',
        })
        return
      }
      this.drawnNumber = i == 1 ? this.drawnNumber + 1 : this.drawnNumber - 1
    },
    // 审核不通过
    changeResult(row, i, state) {
      if (this.disabled) return
      this.auditResult = i
      this.Cid = row.id
      this.curRow = row
      this.srcList = []
      if (!this.showRefult) {
        this.tableItem = this.curRow
      } else {
        this.tableItem2 = this.curRow
      }
      if (i == 1) {
        this.reqResult()
        return
      }
      this.showRefultDialog = true
      if (i >= 0 && i !== 3) {
        this.dialogVisibleResult = true
        this.getimg()
        this.judge = state
        if (this.showRefult) {
          this.completion = row.completion
          this.correctMeasure = row.correctMeasure
          this.completeTime = row.completeTime
          this.judge = state
        } else {
          this.completion = ''
          this.correctMeasure = ''
          this.completeTime = ''
        }
      } else {
        this.reqResult() //是
      }
    },
    getElementHeight() {
      this.$nextTick(() => {
        this.elementHeight =
          window.innerHeight -
          (document.getElementById('pdfDom').offsetTop + 60 + 72)
      })
      
    },
    closeResultBox() {
      this.dialogVisibleResult = false
    },
    reqResult() {
      // if (!this.showRefultDialog) {
      //   //是否打开的是改进弹窗
      //   this.dialogVisibleResult = false
      //   return
      // }
      var item = this.showRefult ? this.tableItem2 : this.tableItem
      var data = {
        id: this.Cid,
        inOutAuditId: Number(this.$route.query.InOutAuditId),
        questionId: item.questionId,
        questionRemark: item.questionRemark,
        correctMeasure: this.correctMeasure,
        completion: this.completion,
        completeTime: new Date().toISOString(),
        auditResult: this.auditResult,
        imagesCount: this.srcList.length,
      }
      put('/api/InOutAuditList', data)
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              type: 'success',
              message: '审核成功',
            })
            this.dialogVisibleResult = false
            this.getTableList()
            this.tableRowIndex = null
          } else {
            this.$message.error(resp.message)
          }
        })
        .catch(() => {
          this.$message.error('审核失败')
        })
    },
    reqNoPassed() {},

    exportExcel2() {
      this.start = 0
      this.end = this.tableData1.length
      this.tableData = this.tableData1
      this.tableData2 = this.tableData1
      this.isshowAns = true
      this.$nextTick(() => {
        exportExcel('#pdfDom', '内外审问题点.xlsx')
        this.start = 0
        this.end = 10
        this.isshowAns = false
        this.getTableList()
      })
    },
    overimg(index) {
      this.Curindex = index
    },
    handlePictureCardPreview() {
      this.dialogVisiblebig = true
    },
    uploadFile(e) {
      if (e.file) {
        compressImage(e.file).then((result) => {
          console.log('size=', result.size, e.file.size)
          if (result.size > e.file.size) {
            // 压缩后比原来更大，则将原图上传
            //调接口上传图片到服务器
            this.uploadFileReq(e.file)
          } else {
            //result压缩结果
            // 压缩后比原来小，上传压缩后的
            //调接口上传图片到服务器
            this.uploadFileReq(result)
          }
        })
      }
    },
    uploadFileReq(file) {
      var formData = new FormData()
      formData.append('files', file)
      post('/api/UpLoadFiles/UploadImg', formData)
        .then((res) => {
          this.$refs.upload.clearFiles()
          if (res.code == 200) {
            var data = {
              inOutAuditListId: this.Cid,
              ImageRemark: '',
              ImageAddress: this.BUrl + '/uploads/' + res.value,
            }
            post('/api/InOutAuditListImage', {
              data: JSON.stringify([data]),
            }).then(() => {
              this.$refs.upload.clearFiles()
              this.getimg()
            })
          } else {
            this.$message.error(res.message)
          }
        })
        .catch(() => {
          this.$refs.upload.clearFiles()
          this.$message.error('上传失败')
        })
    },
    // getaddr(res) {
    //   var data = {
    //     inOutAuditListId: this.Cid,
    //     ImageRemark: '',
    //     ImageAddress: this.BUrl + '/uploads/' + res.value,
    //   }
    //   post('/api/InOutAuditListImage', { data: JSON.stringify([data]) }).then(
    //     (resp) => {
    //       if (resp.code == 200) {
    //         if (this.showRefult) {
    //           this.tableItem2.imagesCount = this.srcList.length
    //         }
    //         this.getimg()
    //       }
    //     }
    //   )
    //   this.$refs.upload.clearFiles()
    // },
    openImg(e) {
      this.Cid = e.id
      this.showRefultDialog = false
      this.dialogVisibleResult = true
      this.tableItem2 = e
      this.getimg()
    },
    getimg() {
      this.srcList = []
      get('/api/InOutAuditListImage/' + this.Cid).then((res) => {
        if (res.value.length != 0) {
          this.srcList = res.value
        }
      })
    },
    delImg(id, index) {
      if (id == undefined) return
      this.$confirm('此操作将永久删除该图片', '是否继续?', '提示')
        .then(() => {
          deleteMethod('/api/InOutAuditListImage?Id=' + id)
            .then((resp) => {
              if (resp.code == 200) {
                this.$message({
                  type: 'success',
                  message: '删除成功!',
                })
                this.srcList.splice(index, 1)
              } else {
                this.$message.error(resp.message)
              }
            })
            .catch(() => {
              this.$message.error('删除成功')
            })
        })
        .catch(() => {})
    },
    // 改进措施
    handleAnswer() {
      this.dialogVisibleAsk = false
    },
    lookAnswer(row) {
      console.log(row)
      this.dialogVisibleAsk = true
      this.detail = row
    },
    // 第二个表格
    rowClick2(row) {
      this.tableRowIndex2 = row.index
      this.tableItem2 = row
      this.tableData2.map((item) => {
        item.checked = false
      })
      row.checked = true
    },

    tableRowClassName2({ row, rowIndex }) {
      row.index = rowIndex
    },
    selectedstyle2({ rowIndex }) {
      if (this.tableRowIndex2 == rowIndex) {
        return {
          'background-color': '#E2E8FA !important',
          'font-weight': 'bold !important',
          color: '#555555',
        }
      }
    },
    // 自定义问题
    toAddASK() {
      if (this.question == '') return
      var data = {
        id: 0,
        inOutAuditId: Number(this.$route.query.InOutAuditId),
        questionId: 0,
        questionRemark: this.question,
        auditResult: null,
        imagesCount: 0,
      }
      post('/api/InOutAuditList', data)
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              type: 'success',
              message: '新增成功',
            })
            this.dialogVisible1 = false
            this.getTableList()
          } else {
            this.$message.error(resp.message)
          }
        })
        .catch(() => {
          this.$message.error('新增失败')
        })
      this.question = ''
    },
    deleteItem() {
      if (this.disabled) return
      var index = this.showRefult ? this.tableRowIndex2 : this.tableRowIndex
      if (index == null) {
        this.$message({
          type: 'warning',
          message: '未选择列表',
        })
        return
      }
      var data = this.showRefult ? this.tableItem2 : this.tableItem
      if (data.questionId != 0) {
        this.$message({
          type: 'warning',
          message: '非自定义问题，不可删除',
        })
        return
      }
      this.$confirm('此操作将永久删除该题目', '是否继续?')
        .then(() => {
          deleteMethod('/api/InOutAuditList?Id=' + data.id)
            .then((resp) => {
              if (resp.code == 200) {
                this.$message({
                  type: 'success',
                  message: '删除成功!',
                })
                this.showRefult
                  ? this.tableData2.splice(this.tableRowIndex2, 1)
                  : this.tableData.splice(this.tableRowIndex, 1)
              } else {
                this.$message.error(resp.message)
              }
            })
            .catch(() => {
              this.$message.error('删除失败')
            })
        })
        .catch(() => {})
    },
    // 完成检查
    completeExam() {
      var data = {
        id: Number(this.$route.query.InOutAuditId),
        companyId: this.$route.query.CompId,
        companyName: this.$route.query.companyName,
        years: this.$route.query.Years,
        auditType: Number(this.$route.query.typeBtnIndex),
        remark: '',
        chargeUserId: JSON.parse(sessionStorage.getItem('userInfo')).id + '',
        planId: '',
        status: 2,
        saveTime: new Date().toISOString(),
        completeTime: new Date().toISOString(),
        saveUserId: JSON.parse(sessionStorage.getItem('userInfo')).id + '',
        saveUserNo: JSON.parse(sessionStorage.getItem('userInfo')).userNo,
        saveUserName: JSON.parse(sessionStorage.getItem('userInfo')).userName,
      }
      put('/api/InOutAudit', data)
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              type: 'success',
              message: '检查成功',
            })
          } else {
            this.$message.error(resp.message)
          }
        })
        .catch(() => {
          this.$message.error('检查失败')
        })
    },
  },
}
</script>

<style lang="less" scoped>
el-upload {
  transform: rotate(0.5);
}
.AnswerBox {
  display: flex;
  align-items: center;
  justify-content: center;
}
.dialog-contain .box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  /deep/ .el-checkbox__label {
    font-size: 12px !important;
  }
  div {
    width: 300px;
    text-align: center;
    input {
      width: 40px;
      border: 1px solid;
      height: 20px;
      border-color: #969696;
      text-align: center;
    }
  }
  .inp {
    display: flex;
    align-items: center;
    justify-content: center;
    .btn {
      width: 30px;
      height: 25px;
      line-height: 25px;
      font-size: 16px;
      display: inline-block;
      background-color: rgb(238, 237, 237);
      color: blue;
      margin: 0 4px;
    }
  }
}

.text {
  font-weight: normal;
  display: flex;
  span {
    font-size: 14px;
  }
  span:nth-of-type(1) {
    width: 80px;
    font-weight: bold;
  }
  span:nth-of-type(2) {
    width: 600px !important;
  }
}

.date {
  margin-bottom: 15px;
  .demonstration {
    display: inline-block;
    width: 90px;
    text-align: right;
    padding-right: 10px;
    font-weight: bold;
  }
}
/deep/ .el-upload,
/deep/.el-textarea textarea {
  background-color: #eeeeee !important;
}
/deep/ .el-form-item__label {
  font-size: 12px !important;
  font-weight: bold;
}
/deep/ .el-form-item {
  margin-bottom: 15px !important;
}
/deep/ .el-table .el-table__cell {
  text-align: left !important;
}
@import '../../assets/style/Examine_CSS.css';
</style>
